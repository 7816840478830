import illustrationResetPW from '@/assets/img/illustration/illustration-resetPW.svg';
import Icon from '@/components/common/icon';
import Illustration from '@/components/common/illustration';
import LanguageFilter from '@/components/common/languageFilter';
import { validatePassword } from '@/shared/helpers/commonHelpers';
import {
  Button,
  Col, Form, Input, message, Row
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import localStorageService from '@/shared/services/localStorageService';
import { STATUS_REQUEST } from '@/constants';
import RegistrationSuccess from '@/components/common/successPage';
import './ChangePassword.less';
import { requestChangePassword, resetChangePasswordState } from './redux/actions';

function ChangePassword() {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const history = useHistory();
  const [isCurrentPasswordError, setIsCurrentPasswordError] = useState(false);
  const [isValidatedPasswordError, setIsValidatedPasswordError] = useState(false);
  const [isChangePasswordSuccess, setIsChangePasswordSuccess] = useState(false);
  const {
    changePasswordReducer: { isLoading, status }
  } = useSelector((state) => state);

  useEffect(() => {
    if (status === STATUS_REQUEST.SUCCESS) {
      setIsChangePasswordSuccess(true);
    }
    if (status === STATUS_REQUEST.FAILED) {
      setIsCurrentPasswordError(true);
      message.error(translate('lbl-change-password-fail'));
    }
    return () => dispatch(resetChangePasswordState());
  }, [dispatch, translate, status]);

  const handleChangePassword = (values) => {
    if (!values.currentPassword || !values.newPassword || !values.reEnterPassword) {
      message.error('Current password, New password and Re-enter Password are required');
    } else {
      setIsValidatedPasswordError(validatePassword(values.newPassword, values.reEnterPassword));
      if (values.currentPassword && !validatePassword(values.newPassword, values.reEnterPassword)) {
        const userInfo = JSON.parse(localStorageService.getuserInfo());
        dispatch(requestChangePassword({
          userId: +userInfo.userId,
          email: userInfo.email,
          passwordOld: values.currentPassword,
          password: values.newPassword
        }));
      }
    }
    return '';
  };
  const renderCurrentPasswordErrorMessage = () => (
    <div className="change-password-content__validate-error">
      {translate('txt_login_registration_error_not_match')}
      <br />
      {translate('txt_login_registration_error_enter_again')}
    </div>
  );
  const renderValidatedPasswordErrorMessage = () => (
    <div className="change-password-content__validate-error">
      {translate('txt_registration_sub_title')}
      <br />
      <ul>
        <li>{translate('txt_registration_rule_1')}</li>
        <li>{translate('txt_registration_rule_2')}</li>
        <li>{translate('txt_registration_rule_3')}</li>
        <li>{translate('txt_registration_rule_4')}</li>
      </ul>
    </div>
  );
  const renderChangePassword = () => (
    <div className="change-password-content">
      <h1>{translate('btn_registration_change_password')}</h1>
      <p>{translate('txt_registration_enter_current_password')}</p>
      <Form form={form} layout="vertical" onFinish={handleChangePassword}>
        <Row gutter={24}>
          <Col span={9}>
            <Form.Item label="Current password">
              <Form.Item name="currentPassword">
                <Input.Password
                  size="large"
                  style={{ borderColor: isCurrentPasswordError && '#DD3804' }}
                />
              </Form.Item>
            </Form.Item>
          </Col>
        </Row>
        { isCurrentPasswordError && renderCurrentPasswordErrorMessage()}
        <p>{translate('txt_registration_enter_new_password')}</p>
        <Row gutter={24}>
          <Col span={9}>
            <Form.Item label={translate('txt_registration_new_password')}>
              <Form.Item name="newPassword">
                <Input.Password
                  size="large"
                  style={{ borderColor: isValidatedPasswordError && '#DD3804' }}
                />
              </Form.Item>
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item label={translate('txt_registration_reEnter_password')}>
              <Form.Item name="reEnterPassword">
                <Input.Password
                  size="large"
                  style={{ borderColor: isValidatedPasswordError && '#DD3804' }}
                />
              </Form.Item>
              <div className="change-password-content__btn-reset">
                <Button
                  type="text"
                  className="change-password-content__btn-reset-action"
                >
                  {translate('txt_registration_title')}
                  ?
                </Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
        {isValidatedPasswordError && renderValidatedPasswordErrorMessage()}
        <Form.Item className="change-password-content__btn">
          <Button
            type="primary"
            loading={isLoading}
            className="change-password-content__btn-change"
            htmlType="submit"
          >
            {translate('btn_registration_change_password')}
          </Button>
          <Button type="text" onClick={() => history.goBack()}>
            {translate('txt_registration_keep_current_password')}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
  return (
    <div className="auth-container">
      <div className="change-password-container">
        <div className="change-password-header">
          <Icon.SoniqLogoIcon />
          <LanguageFilter />
        </div>
        {isChangePasswordSuccess ? <RegistrationSuccess title="Password change" /> : renderChangePassword()}
      </div>
      <div className="illustration-container">
        <Illustration isLogin={false} illustrationImg={illustrationResetPW} />
      </div>
    </div>
  );
}

export default ChangePassword;
