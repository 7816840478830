/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import Navigation from '@/components/navigation';
import cookieService from '@/shared/services/cookieService';
import { Layout } from 'antd';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { handleCheckPublicRoute } from '@/shared/helpers/commonHelpers';
import LayoutModule from '../layoutModule';
import './LayoutNavigation.less';

const LayoutNavigation = ({ children }) => {
  const location = useLocation();
  const {
    loginReducer: { isAuthenticated }
  } = useSelector((state) => state);

  const isPublicRoute = handleCheckPublicRoute(location.pathname);
  const isAuthen = isAuthenticated || cookieService.getCookie('access_token');
  const isShowNavigation = useMemo(() => (!isPublicRoute && isAuthen), [
    isPublicRoute,
    isAuthen
  ]);
  const genNavigationHeader = useMemo(() => <Navigation type="header" />, []);
  const genNavigationSideBar = useMemo(() => <Navigation type="sidebar" />, []);

  return (
    <Layout className="app-container">
      {isShowNavigation && genNavigationHeader}
      <Layout>
        {isShowNavigation && genNavigationSideBar}
        <LayoutModule>
          {children}
        </LayoutModule>
      </Layout>
    </Layout>
  );
};

LayoutNavigation.propTypes = {
  children: PropTypes.element
};

export default LayoutNavigation;
