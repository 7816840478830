import * as types from '@/constants/actionTypes';
import {
  call, fork, put, takeLatest
} from '@redux-saga/core/effects';
import * as actions from './actions';
import api from '../api';

function* changePassword(action) {
  try {
    const response = yield call(api.changePasswordApi, action.payload);
    yield put(actions.requestChangePasswordSuccess(response));
  } catch (error) {
    yield put(actions.requestChangePasswordFailed(error));
  }
}

function* watchChangePassword() {
  yield takeLatest(types.CHANGE_PASSWORD_ACTION_TYPES.CHANGE_PASSWORD_REQUEST, changePassword);
}

const changePasswordSaga = [
  changePassword,
  fork(watchChangePassword)
];
export default changePasswordSaga;