import * as types from '@/constants/actionTypes';

export const initialState = {
  isLoading: false,
  isSuccess: false
};

const resetPasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_PASSWORD_ACTION_TYPES.RESET_PASSWORD_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isSuccess: false
      };
    }
    case types.RESET_PASSWORD_ACTION_TYPES.RESET_PASSWORD_REQUEST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isSuccess: true
      };
    }
    case types.RESET_PASSWORD_ACTION_TYPES.RESET_PASSWORD_REQUEST_FAILED: {
      return {
        ...state,
        isLoading: false,
        isSuccess: false
      };
    }
    default:
      return state;
  }
};

export default resetPasswordReducer;
