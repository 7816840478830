import * as ActionTypes from '@/constants/actionTypes';

export const initialState = {
  isLoading: true,
  events: [],
  locations: null
};

const calendarReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.CALENDAR_ACTION_TYPES.FETCH_EVENTS: {
      const events = action.payload.data;
      return {
        ...state,
        isLoading: false,
        events
      };
    }
    case ActionTypes.CALENDAR_ACTION_TYPES.FETCH_EVENT: {
      const event = action.payload.data;
      return {
        ...state,
        isLoading: false,
        event
      };
    }
    case ActionTypes.CALENDAR_ACTION_TYPES.SET_LOADING: {
      return {
        ...state,
        isLoading: true
      };
    }
    default: {
      return state;
    }
  }
};

export default calendarReducer;
