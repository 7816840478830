import * as Avatar from '@/components/common/avatar';
import { Col, Input, Row } from 'antd';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { SettingContext } from '../../index';
import './SettingContact.less';

const InputContactForm = ({ label, id }) => {
  const { t: translate } = useTranslation();
  const context = useContext(SettingContext);
  const { settingFromValue, handleChangeSettingValue } = context;
  const { settingReducer: { isLoading } } = useSelector((state) => state);
  const handleChangeValue = (e) => {
    e.preventDefault();
    const { value } = e.target;
    handleChangeSettingValue(id, value);
  };

  return (
    <div className="contact-info-form">
      <div className="label">{translate(label)}</div>
      <Input
        placeholder={translate(label)}
        className="input"
        disabled={isLoading}
        onChange={handleChangeValue}
        value={context && settingFromValue[id]}
      />
    </div>
  );
};

InputContactForm.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string
};

InputContactForm.defaultProps = {
  id: '',
  label: ''
};
const SettingContact = () => {
  const { t: translate } = useTranslation();
  return (
    <div className="contact-info-container">
      <div className="avatar-update-container">
        <Avatar.AvatarNoNamePlaceHolder size="large" />
      </div>
      <h1 className="contact-info-title">
        {translate('txt_setting_contact_information')}
      </h1>
      <h1 className="contact-info-sub-title">
        {translate('txt_setting_contact_sub_information')}
      </h1>
      <div className="contact-info-form-container">
        <Row gutter={16}>
          <Col span={8}>
            <InputContactForm
              id="name"
              label="txt_setting_contact_info_form_name_title"
            />
          </Col>
          <Col span={8}>
            <InputContactForm
              id="surname"
              label="txt_setting_contact_info_form_surname_title"
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SettingContact;
