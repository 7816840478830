/* eslint-disable import/prefer-default-export */

export const validatePassword = (password, reEnterPassword) => {
  const isRegexLowerCase = /[a-z]{1,}/g.test(password);
  const isRegexUppercase = /[A-Z]{1,}/g.test(password);
  const isRegexNumeric = /[0-9]{1,}/g.test(password);

  if (
    password !== reEnterPassword
    || !(password.length >= 8)
    || !isRegexLowerCase
    || !isRegexUppercase
    || !isRegexNumeric
  ) {
    return true;
  }
  return false;
};

const publicRouteArr = ['login', 'reset-password', 'change-password'];

export const handleCheckPublicRoute = (route) => {
  const countArray = [];
  publicRouteArr.map((item) => countArray.push(route.includes(item)));
  return countArray.filter((item) => item === true).length > 0;
};
