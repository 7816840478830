import * as types from '@/constants/actionTypes';

export const requestLogin = ({ email, password, languageSelected }) => ({
  type: types.AUTH_ACTION_TYPES.LOGIN_REQUEST,
  payload: {
    email,
    password,
    languageSelected
  }
});

export const requestLoginSuccess = (userInfo) => ({
  type: types.AUTH_ACTION_TYPES.LOGIN_REQUEST_SUCCESS,
  payload: {
    userInfo
  }
});

export const requestLoginFailed = () => ({
  type: types.AUTH_ACTION_TYPES.LOGIN_REQUEST_FAILED
});

export const logoutUser = () => ({
  type: types.AUTH_ACTION_TYPES.LOGOUT_USER
});
