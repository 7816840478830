import Icon from '@/components/common/icon';
import { Button } from 'antd';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CalendarDatePicker from '../calendarDatePicker';
import './CalendarHeader.less';

const CalendarHeader = ({
  title,
  onChangeView,
  onRefreshEvent,
  viewMode,
  onSelectDay,
  onClickPaginator
}) => {
  const { t: translate } = useTranslation();
  const genBtnView = (mode) => classnames('btn-view-calendar', {
    active: viewMode === mode,
    'icon-xl': mode.includes('icon-xl'),
    'icon-md': mode.includes('icon-md'),
    text: mode.includes('text')
  });
  return (
    <div className="calendar-header-container">
      <div className="calendar-header-option">
        <div className="left">
          <div className="navigate-button">
            <Icon.LeftOutlined
              className="pre-button"
              id="pre-btn"
              onClick={(e) => onClickPaginator(e, 'prev')}
            />
            <Button
              shape="square"
              className={genBtnView('icon-md')}
              icon={<CalendarDatePicker onSelectedDay={onSelectDay} />}
            >
              <span className="title">{title}</span>
            </Button>
            <Icon.RightOutlined
              className="next-button"
              id="next-btn"
              onClick={(e) => onClickPaginator(e, 'next')}
            />
            <Button
              size="large"
              className="today-button"
              id="today-btn"
              onClick={(e) => onClickPaginator(e, 'today')}
            >
              {translate('lbl_today')}
            </Button>
          </div>
        </div>
        <div className="right">
          <Button
            type="text"
            shape="square"
            className={genBtnView('timeGridDay')}
            id="date-view-btn"
            onClick={(e) => onChangeView(e, 'timeGridDay')}
          >
            {translate('btn_calendar_view_by_day')}
          </Button>
          <Button
            type="text"
            shape="square"
            className={genBtnView('timeGridWeek')}
            id="week-view-btn"
            onClick={(e) => onChangeView(e, 'timeGridWeek')}
          >
            {translate('btn_calendar_view_by_week')}
          </Button>
          <Button
            type="text"
            shape="square"
            className={genBtnView('dayGridMonth')}
            id="month-view-btn"
            onClick={(e) => onChangeView(e, 'dayGridMonth')}
          >
            {translate('btn_calendar_view_by_month')}
          </Button>

          <Button
            shape="square"
            className={genBtnView('icon-md')}
            icon={<Icon.ReloadCalendar />}
            onClick={onRefreshEvent}
          />
        </div>
      </div>
    </div>
  );
};

export default CalendarHeader;

CalendarHeader.propTypes = {
  onChangeView: PropTypes.func,
  onClickPaginator: PropTypes.func,
  onRefreshEvent: PropTypes.func,
  onSelectDay: PropTypes.func,
  title: PropTypes.string,
  viewMode: PropTypes.string
};

CalendarHeader.defaultProps = {
  onChangeView: () => {},
  onClickPaginator: () => {},
  onRefreshEvent: () => {},
  onSelectDay: () => {},
  title: '',
  viewMode: ''
};
