import axios from 'axios';

const fetchEventsAPI = async (params) => {
  const queryString = `from=${params.startDate}&to=${params.endDate}&location=${params.location}`;
  const apiFetchEvents = `${process.env.REACT_APP_API_ENDPOINT}/events?${queryString}`;
  const { data } = await axios.get(apiFetchEvents);
  return data;
};

export default fetchEventsAPI;
