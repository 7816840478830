import { openDrawer, closeDrawer } from './redux/actions';
import drawerReducer from './redux/reducer';
import Drawer from './Drawer';

const drawerAction = {
  openDrawer,
  closeDrawer
};
export {
  drawerReducer, Drawer, drawerAction
};
