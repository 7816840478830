/* eslint-disable no-param-reassign */
import axios from 'axios';
// import { STATUS_CODE } from '@/constants';
import cookieService from './cookieService';

axios.interceptors.request.use(
  (config) => {
    const token = cookieService.getCookie('access_token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => response,
  // (error) => {
  //   // if (error.response.status === STATUS_CODE.UNAUTHORIZATION) { window.location.href = '/login'; }

  //   // throw new Error(error);
  // }
);
