export const CALENDAR_ACTION_TYPES = {
  FETCH_EVENTS_REQUEST: 'calendar/FETCH_EVENTS_REQUEST',
  FETCH_EVENTS: 'calendar/FETCH_EVENTS',
  FETCH_EVENTS_FAILED: 'calendar/FETCH_EVENTS_FAILED',
  FETCH_EVENT_REQUEST: 'calendar/FETCH_EVENT_REQUEST',
  FETCH_EVENT: 'calendar/FETCH_EVENT',
  FETCH_EVENT_FAILED: 'calendar/FETCH_EVENT_FAILED',
  FETCH_LOCATIONS_REQUEST: 'calendar/FETCH_LOCATION_REQUEST',
  FETCH_LOCATIONS: 'calendar/FETCH_LOCATIONS',
  FETCH_LOCATIONS_FAILED: 'calendar/FETCH_LOCATIONS_FAILED',
  SET_LOADING: 'calendar/SET_LOADING'
};

export const RESET_PASSWORD_ACTION_TYPES = {
  RESET_PASSWORD_REQUEST: 'resetPassword/RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_REQUEST_SUCCESS:
    'resetPassword/RESET_PASSWORD_REQUEST_SUCCESS',
  RESET_PASSWORD_REQUEST_FAILED: 'resetPassword/RESET_PASSWORD_REQUEST_FAILED'
};

export const AUTH_ACTION_TYPES = {
  LOGIN_REQUEST: 'login/LOGIN_REQUEST',
  LOGIN_REQUEST_SUCCESS: 'login/LOGIN_REQUEST_SUCCESS',
  LOGIN_REQUEST_FAILED: 'login/LOGIN_REQUEST_FAILED',
  LOGOUT_USER: 'login/LOGOUT_USER'
};

export const DRAWER_ACTION_TYPES = {
  OPEN_DRAWER: 'OPEN_DRAWER',
  CLOSE_DRAWER: 'CLOSE_DRAWER'
};

export const SETTING_ACTION_TYPES = {
  UPDATE_USER_REQUEST: 'setting/UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCESS: 'setting/UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILED: 'setting/UPDATE_USER_FAILED',
  SET_LOADING: 'setting/SET_LOADING'
};

export const CHANGE_PASSWORD_ACTION_TYPES = {
  CHANGE_PASSWORD_REQUEST: 'changePassword/CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_REQUEST_SUCCESS:
    'changePassword/CHANGE_PASSWORD_REQUEST_SUCCESS',
  CHANGE_PASSWORD_REQUEST_FAILED: 'changePassword/CHANGE_PASSWORD_REQUEST_FAILED',
  RESET_CHANGE_PASSWORD_STATE: 'changePassword/RESET_CHANGE_PASSWORD_STATE'
};
