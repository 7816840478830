import * as types from '@/constants/actionTypes';
import { STATUS_REQUEST } from '@/constants';

export const initialState = {
  isLoading: false,
  status: STATUS_REQUEST.REQUEST
};

const changePasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_PASSWORD_ACTION_TYPES.CHANGE_PASSWORD_REQUEST: {
      return {
        ...state,
        isLoading: true,
        status: STATUS_REQUEST.REQUEST
      };
    }
    case types.CHANGE_PASSWORD_ACTION_TYPES.CHANGE_PASSWORD_REQUEST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        status: STATUS_REQUEST.SUCCESS
      };
    }
    case types.CHANGE_PASSWORD_ACTION_TYPES.CHANGE_PASSWORD_REQUEST_FAILED: {
      return {
        ...state,
        isLoading: false,
        status: STATUS_REQUEST.FAILED
      };
    }
    case types.CHANGE_PASSWORD_ACTION_TYPES.RESET_CHANGE_PASSWORD_STATE: {
      return initialState;
    }
    default:
      return state;
  }
};

export default changePasswordReducer;