const setToken = (token) => {
  localStorage.setItem('access_token', token);
};

const getAccessToken = () => localStorage.getItem('access_token');

const clearToken = () => {
  localStorage.removeItem('access_token');
};

const setUserInfo = ({ userInfo }) => {
  localStorage.setItem('user_info', JSON.stringify(userInfo));
};

const getuserInfo = () => localStorage.getItem('user_info');
const localStorageService = {
  setToken,
  getAccessToken,
  clearToken,
  setUserInfo,
  getuserInfo
};

export default localStorageService;
