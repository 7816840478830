import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

const configSentry = () => {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.REACT_APP_ENV,
    tracesSampleRate: 1.0,
  });
};

export default configSentry();
