import axios from 'axios';

const fetchEventAPI = async (params) => {
  const queryString = `${params.eventId}`;
  const apiFetchEvent = `${process.env.REACT_APP_API_ENDPOINT}/events/${queryString}`;
  const { data } = await axios.get(apiFetchEvent);
  return data;
};

export default fetchEventAPI;
