import illustrationIcon from '@/assets/img/illustration/illustration-icon.svg';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './Illustration.less';

const IllustrationSlider = () => {
  const { t: translate } = useTranslation();
  const settings = {
    className: 'slider content-slider',
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000
  };
  return (
    <Slider {...settings}>
      <div className="content-slider">
        <div className="text">{translate('txt_illustration_content_1')}</div>
        <div className="sign">{translate('txt_illustration_sign_1')}</div>
      </div>
      <div className="content-slider">
        <div className="text">{translate('txt_illustration_content_2')}</div>
        <div className="sign">{translate('txt_illustration_sign_2')}</div>
      </div>
      <div className="content-slider">
        <div className="text">{translate('txt_illustration_content_3')}</div>
        <div className="sign">{translate('txt_illustration_sign_3')}</div>
      </div>
    </Slider>
  );
};

const Illustration = ({ isLogin, illustrationImg }) => {
  const { t: translate } = useTranslation();
  return (
    <div className="illustration">
      {isLogin ? (
        <>
          <div className="content-title">
            {translate('txt_illustration_title')}
          </div>
          <img className="content-img" src={illustrationImg} alt="content" />
          <div className="wapper-slider">
            <img className="content-icon" src={illustrationIcon} alt="content" />
            {IllustrationSlider()}
          </div>
        </>
      ) : (
        <img className="content-img" src={illustrationImg} alt="content" />
      )}
    </div>
  );
};

Illustration.propTypes = {
  illustrationImg: PropTypes.string,
  isLogin: PropTypes.bool
};

Illustration.defaultProps = {
  illustrationImg: '',
  isLogin: false
};

export default Illustration;
