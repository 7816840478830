import { Skeleton } from 'antd';
import classnames from 'classnames';
import { format } from 'date-fns';
import get from 'lodash.get';
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const statusDetail = (status) => {
  switch (status) {
    case 'active':
      return 'lbl-in-progress';
    case 'pause':
      return 'lbl-pause';
    case 'inactive':
      return 'lbl-in-active';
    default:
      break;
  }
  return '';
};
// eslint-disable-next-line react/prop-types
const GenStatus = ({ drawerData }) => {
  const { t: translate } = useTranslation();
  const status = get(drawerData, 'status');

  const prcesslabelClass = classnames('process-label', {
    inprogress: statusDetail(status) === 'lbl-in-progress',
    pause: statusDetail(status) === 'lbl-pause',
    inactive: statusDetail(status) === 'lbl-in-active'
  });

  return (
    <div className={prcesslabelClass}>
      {translate(statusDetail(status))}
    </div>
  );
};

const GenDateTime = ({ drawerData, mode, formatType }) => {
  const start = get(drawerData, 'startDateTime');
  const end = get(drawerData, 'endDateTime');
  if (drawerData) {
    const formatForm = (date) => format(new Date(date), formatType);
    switch (mode) {
      case 'date':
        return formatForm(start);
      case 'time':
        if (end) {
          return `${formatForm(start)} - ${formatForm(end)}`;
        }
        return `${formatForm(start)}`;
      default:
        break;
    }
  }
  return '';
};

// eslint-disable-next-line react/prop-types
const GenHeader = ({ drawerData }) => {
  const title = get(drawerData, 'name');
  const location = get(drawerData, 'locationName');
  const street = get(drawerData, 'shippingStreet');
  const city = get(drawerData, 'shippingCity');
  const date = GenDateTime({
    drawerData,
    mode: 'date',
    formatType: 'dd LLLL yyyy'
  });
  const time = GenDateTime({ drawerData, mode: 'time', formatType: 'kk:mm' });
  return (
    <div className="header-drawer-container">
      <div className="title">{title}</div>
      <div className="time">
        {date && time && (
          <p>
            {date}
            ,
            {time}
          </p>
        )}
      </div>
      <GenStatus drawerData={drawerData} />
      <div className="location">{location}</div>
      <span className="location-detail">
        {street && city && `${street},${city}`}
      </span>
    </div>
  );
};

const DrawerEventHeader = () => {
  const {
    calendarReducer: { event, isLoading }
  } = useSelector((state) => state);
  if (isLoading) {
    return (
      <div className="header-drawer-container">
        <Skeleton active />
      </div>
    );
  }
  return <GenHeader drawerData={event} />;
};

export default DrawerEventHeader;
