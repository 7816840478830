import * as ActionTypes from '@/constants/actionTypes';
import uniq from 'lodash.uniq';

export const inititalState = {
  drawerArr: []
};

const drawerReducer = (state = inititalState, action) => {
  switch (action.type) {
    case ActionTypes.DRAWER_ACTION_TYPES.OPEN_DRAWER: {
      const drawerArrClone = [...state.drawerArr];
      drawerArrClone.push(action.payload.drawerType);
      return {
        ...state,
        drawerArr: uniq(drawerArrClone)
      };
    }
    case ActionTypes.DRAWER_ACTION_TYPES.CLOSE_DRAWER: {
      const drawerArrClone = [...state.drawerArr];
      const index = drawerArrClone.findIndex(
        (i) => i === action.payload.drawerType
      );
      drawerArrClone.splice(index, 1);
      return {
        ...state,
        drawerArr: drawerArrClone
      };
    }
    default:
      return state;
  }
};

export default drawerReducer;
