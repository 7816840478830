import Icon from '@/components/common/icon';
import { Button, Dropdown, Menu } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import './LanguageFilter.less';

const MenuTranslateLanguage = (translate, handleSelected) => (
  <div className="menu-translate-language-container ">
    <Menu className="menu-translate-language">
      <Menu.Item key="en" id="en" onClick={handleSelected}>
        {translate('lbl_english')}
      </Menu.Item>
      <Menu.Item key="de" id="de" onClick={handleSelected}>
        {translate('lbl_german')}
      </Menu.Item>
    </Menu>
  </div>
);

const LanguageFilter = ({ border }) => {
  const { t: translate, i18n } = useTranslation();
  const parseLanguage = i18n.language === 'en' ? 'English' : 'Germand';

  const handleSelected = useCallback((e) => {
    i18n.changeLanguage(e.key);
  }, [i18n]);
  const genMenuTranslateLanguage = useMemo(
    () => MenuTranslateLanguage(translate, handleSelected),
    [handleSelected, translate]
  );

  const filterClassname = classNames('language-filter-button', {
    border
  });
  return (
    <div className="language-filter-container">
      <Dropdown overlay={genMenuTranslateLanguage} trigger={['click']}>
        <Button className={filterClassname}>
          <Icon.LanguageIcon />
          <div className="language-filter-text">{translate(parseLanguage)}</div>
          <Icon.DownOutlined />
        </Button>
      </Dropdown>
    </div>
  );
};

LanguageFilter.propTypes = {
  border: PropTypes.bool
};

LanguageFilter.defaultProps = {
  border: false
};

export default LanguageFilter;
