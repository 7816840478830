import * as ActionTypes from '@/constants/actionTypes';

export const setSettingLoading = () => ({
  type: ActionTypes.SETTING_ACTION_TYPES.SET_LOADING
});

// user update
export const updateUserRequest = ({
  firstName,
  lastName,
  phone,
  language
}) => ({
  type: ActionTypes.SETTING_ACTION_TYPES.UPDATE_USER_REQUEST,
  payload: {
    firstName,
    lastName,
    phone,
    language
  }
});
export const updateUserSuccess = (data) => ({
  type: ActionTypes.SETTING_ACTION_TYPES.UPDATE_USER_SUCCESS,
  payload: data
});

export const updateUserFailed = (error) => ({
  type: ActionTypes.SETTING_ACTION_TYPES.UPDATE_USER_FAILED,
  payload: error
});
