import {
  Button, Col, Input, Row
} from 'antd';
import PropTypes from 'prop-types';
import React, {
  useCallback, useContext, useEffect, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-number-input/input';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SettingContext } from '../../index';
import './SettingLogin.less';

const InputLoginForm = ({
  label, disabled, id, type, subLabel
}) => {
  const { t: translate } = useTranslation();
  const { settingFromValue, handleChangeSettingValue } = useContext(
    SettingContext
  );
  const [valueNumber, setValueNumber] = useState(settingFromValue.number);
  const { settingReducer: { isLoading } } = useSelector((state) => state);
  const handleChangeValue = (e) => {
    e.preventDefault();
    const { value } = e.target;
    handleChangeSettingValue(id, value);
  };
  const handleChangeValuePhoneNumber = useCallback(() => {
    handleChangeSettingValue('number', valueNumber);
  }, [handleChangeSettingValue, valueNumber]);
  useEffect(() => {
    handleChangeValuePhoneNumber();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueNumber]);

  const GenTitle = () => (
    <div className="label">
      {translate(label)}
      <span className="sub-label">{`${translate(subLabel)}`}</span>
    </div>
  );
  return (
    <div className="login-info-form">
      <GenTitle />
      {type === 'phoneNumber' ? (
        <PhoneInput
          className="input"
          placeholder={translate(label)}
          value={settingFromValue[id]}
          onChange={setValueNumber}
          disabled={isLoading}
        />
      ) : (
        <Input
          placeholder={translate(label)}
          type={type}
          className="input"
          disabled={disabled}
          value={settingFromValue[id]}
          onChange={handleChangeValue}
        />
      )}
    </div>
  );
};

InputLoginForm.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  subLabel: PropTypes.string,
  type: PropTypes.string
};
InputLoginForm.defaultProps = {
  disabled: false,
  id: '',
  label: '',
  subLabel: '',
  type: ''
};
const SettingLogin = () => {
  const { t: translate } = useTranslation();
  const history = useHistory();
  return (
    <div className="login-info-container">
      <h1 className="login-info-title">
        {translate('txt_setting_login_information')}
      </h1>
      <h1 className="login-info-sub-title">
        {translate('txt_setting_login_sub_information')}
      </h1>
      <div className="login-info-form-container">
        <Row gutter={16}>
          <Col span={8}>
            <InputLoginForm
              label="txt_setting_login_info_form_email_title"
              id="email"
              disabled
            />
          </Col>
          <Col span={8}>
            <InputLoginForm
              label="txt_setting_login_info_form_phone_title"
              id="number"
              type="phoneNumber"
            />
          </Col>
        </Row>
      </div>
      <div className="login-info-form-container">
        <Row gutter={16}>
          <Col span={8}>
            <InputLoginForm
              label="txt_setting_login_info_form_password_title"
              subLabel="txt_setting_login_info_form_password_subtitle"
              type="password"
              disabled
            />
          </Col>
          <Col span={8} className="change-password-col">
            <Button className="change-password-button" onClick={() => history.push('/change-password')}>
              {translate('lbl_change_password')}
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SettingLogin;
