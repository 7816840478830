import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import configureStore from '@/configs/store.config';
import '@/styles/index.less';
import '@/i18n';
import { ModulesRender } from '@/routes';
import '@/shared/services/axios.service';
import { message } from 'antd';
import '@/configs/sentry.config';

document.title = 'Commercial Interface';

const store = configureStore({});

// config message toast
message.config({
  duration: 1.5,
  maxCount: 1,
  rtl: true,
});

const App = () => (
  <React.StrictMode>
    <BrowserRouter>
      <ModulesRender />
    </BrowserRouter>
  </React.StrictMode>
);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

export default App;
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
