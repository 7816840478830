/* eslint-disable no-unreachable */
import {
  takeLatest, call, put, fork
} from 'redux-saga/effects';
import * as ActionTypes from '@/constants/actionTypes';
import api from '../api';
import * as actions from './actions';

function* updateUser(params) {
  const { payload } = params;
  try {
    yield put(actions.setSettingLoading());
    const result = yield call(api.updateUserApi, payload);
    yield put(actions.updateUserSuccess(result));
  } catch (e) {
    yield put(actions.updateUserFailed(e));
  }
}

function* watchUpdateUserRequest() {
  yield takeLatest(
    ActionTypes.SETTING_ACTION_TYPES.UPDATE_USER_REQUEST,
    updateUser
  );
}

const settingSagas = [
  updateUser,
  fork(watchUpdateUserRequest)
];

export default settingSagas;
