import * as ActionTypes from '@/constants/actionTypes';

export const openDrawer = (drawerType) => ({
  type: ActionTypes.DRAWER_ACTION_TYPES.OPEN_DRAWER,
  payload: {
    drawerType
  }
});

export const closeDrawer = (drawerType) => ({
  type: ActionTypes.DRAWER_ACTION_TYPES.CLOSE_DRAWER,
  payload: {
    drawerType
  }
});
