import axios from 'axios';

const changePasswordApi = async (params) => {
  const apichangePassword = `${process.env.REACT_APP_API_ENDPOINT}/change-password`;
  const { data } = await axios.post(apichangePassword, {
    ...params
  });
  return data;
};

export default changePasswordApi;