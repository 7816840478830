import axios from 'axios';

const requestLogin = async (params) => {
  const { email, password, languageSelected } = params;
  const { data } = await axios.post(
    `${process.env.REACT_APP_API_ENDPOINT}/login`,
    {
      email,
      password,
      language: languageSelected
    }
  );
  return data;
};

export default requestLogin;
