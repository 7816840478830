import * as DrawerComnponent from '@/components/common/drawer';
import Layout from '@/components/common/layout';
import allRoutes from '@/configs/router.config';
import * as RouteType from '@/routes';
import cookieService from '@/shared/services/cookieService';
import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { name } = rest;
  const isAuthen = cookieService.getCookie('access_token');
  document.title = name;
  return (
    <Route
      {...rest}
      render={(props) => (isAuthen ? (
        <>
          <Component {...props} />
          <DrawerComnponent.Drawer />
        </>
      ) : (
        <Redirect to={{ pathname: '/login' }} />
      ))}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.func
};

PrivateRoute.defaultProps = {
  component: () => {}
};

const PublicRoute = ({ component: Component, ...rest }) => {
  const { name, path } = rest;
  document.title = name;
  return (
    <Route path={path}>
      <Component />
    </Route>
  );
};

PublicRoute.propTypes = {
  component: PropTypes.func
};
PublicRoute.defaultProps = {
  component: () => {}
};

export const ModulesRender = () => (
  <Layout>
    <Switch>
      {allRoutes.map((item) => {
        const { isPublic, name } = item;
        return isPublic ? (
          <RouteType.PublicRoute key={name} {...item} />
        ) : (
          <RouteType.PrivateRoute key={name} {...item} />
        );
      })}
      <Route component={() => <h1>Not found</h1>} />
    </Switch>
  </Layout>
);

export { PrivateRoute, PublicRoute };
