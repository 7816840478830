import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DrawerEvent from './components/drawerEvent';
import { closeDrawer } from './redux/actions';
// const DrawerComponent = ({ header, content, width, placement }) => {

//   return (

//   );
// };
const Drawer = () => {
  const {
    drawerReducer: { drawerArr }
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  const onClose = (drawerType) => {
    dispatch(closeDrawer(drawerType));
  };
  return (
    <>
      <DrawerEvent
        visible={drawerArr.includes('eventDetailDrawer')}
        onClose={() => onClose('eventDetailDrawer')}
      />
    </>
  );
};
export default Drawer;
