import * as types from '@/constants/actionTypes';

export const requestResetPassword = ({ password, token }) => ({
  type: types.RESET_PASSWORD_ACTION_TYPES.RESET_PASSWORD_REQUEST,
  payload: {
    password,
    token
  }
});

export const requestResetPasswordSuccess = () => ({
  type: types.RESET_PASSWORD_ACTION_TYPES.RESET_PASSWORD_REQUEST_SUCCESS
});

export const requestResetPasswordFailed = () => ({
  type: types.RESET_PASSWORD_ACTION_TYPES.RESET_PASSWORD_REQUEST_FAILED
});
