import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './successPage.less';

const RegistrationSuccess = ({ title }) => {
  const history = useHistory();
  const [timeLeft, setTimeLeft] = useState(5);
  useEffect(() => {
    if (timeLeft === 0) {
      history.push('/login');
    }
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeLeft, history]);

  return (
    <div className="login-header">
      <h1 className="title">
        {`${title} successful`}
      </h1>
      <p>
        {`You will be automatically redirected to the Commercial Interface in ${timeLeft}`}
      </p>
    </div>
  );
};

RegistrationSuccess.propTypes = {
  title: PropTypes.string
};

RegistrationSuccess.defaultProps = {
  title: ''
};

export default RegistrationSuccess;
