import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import LayoutNavigation from './components/layoutNavigation';

const Layout = ({ children }) => {
  const genLayoutNavigation = useMemo(
    () => (
      <LayoutNavigation>
        {children}
      </LayoutNavigation>
    ),
    [children]
  );
  return genLayoutNavigation;
};

Layout.propTypes = {
  children: PropTypes.element
};

export default Layout;
