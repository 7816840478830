/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Icon from '@/components/common/icon';
import { Collapse, Skeleton, Empty } from 'antd';
import classnames from 'classnames';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';


const { Panel } = Collapse;

const DrawerEventTask = ({ label, status }) => {
  const taskItemClass = classnames('', {
    checked: status
  });
  return (
    <div className="task-item-container">
      <div className="task-item-status">
        <div className="round">
          <label htmlFor="icon-check-outline" className={taskItemClass}>
            {status && <Icon.CheckOutlined id="icon-check-outline" />}
          </label>
        </div>
      </div>
      <div className="task-item-label">{label}</div>
    </div>
  );
};

DrawerEventTask.propTypes = {
  label: PropTypes.string,
  status: PropTypes.string
};

DrawerEventTask.defaultProps = {
  label: '',
  status: ''
};

const genMenuHeader = (type) => (
  <div className="menu-item">
    <Icon.TaskIcon />
    <span className="menu-type-label">{type}</span>
  </div>
);

export const DrawerEventHeader = () => {
  const [taskSttArr, setTaskSttArr] = useState([]);
  const {
    calendarReducer: { event, isLoading }
  } = useSelector((state) => state);
  const { t: translate } = useTranslation();
  const onHandleTask = (e, id) => {
    e.preventDefault();
    const taskSttArrClone = [...taskSttArr];
    const position = taskSttArrClone.findIndex((i) => i === id);
    if (position === -1) {
      taskSttArrClone.push(id);
    } else {
      taskSttArrClone.splice(position, 1);
    }
    setTaskSttArr(taskSttArrClone);
  };
  const isTaskGroupRender = !isLoading && get(event, 'tasksGroup').length === 0;
  if (isLoading) {
    return (
      <div className="header-drawer-container">
        <Skeleton active />
      </div>
    );
  }

  return (
    <div className="content-drawer-container">

      <Collapse expandIconPosition="right">
        <Panel
          key={1}
          header={genMenuHeader(
            translate('lbl_calendar_menu_drawer_event_detail_type_1')
          )}
        >
          <div className="content-drawer-container">
            {isTaskGroupRender ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> : event.tasksGroup.map((taskGroup) => {
              const { id, tasks, name } = taskGroup;
              const taskDetailClass = classnames('task-detail', {
                expand: taskSttArr.includes(id)
              });
              return (
                <div className="task-container" key={id}>
                  <div className="area">{name}</div>
                  <div className={taskDetailClass}>
                    {tasks.map((task) => {
                      const { id: idTask, name: nameTask, employeeTask } = task;
                      const status = get(employeeTask, 'status')
                          && get(employeeTask, 'status') === 'checked';
                      return (
                        <DrawerEventTask
                          id={idTask}
                          key={idTask}
                          label={nameTask}
                          status={status}
                        />
                      );
                    })}
                  </div>
                  <div
                    className="expand-btn"
                    onClick={(e) => onHandleTask(e, id)}
                  >
                    {translate(
                      taskSttArr.includes(id)
                        ? 'btn_hide_task'
                        : 'btn_show_task'
                    )}
                  </div>
                </div>
              );
            })}

          </div>
        </Panel>
      </Collapse>

    </div>
  );
};

export default DrawerEventHeader;
