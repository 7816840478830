import * as CalendarModule from '@/components/calendar';
import * as LoginModule from '@/components/login';
import * as ResetPasswordModule from '@/components/resetPassword';
import * as SettingModule from '@/components/setting';
import * as ChangePasswordModule from '@/components/changePassword';

const allRoutes = [
  // default
  {
    path: '/',
    component: CalendarModule.Calendar,
    name: 'Calendar',
    exact: true
  },
  // calendar
  {
    path: '/calendar',
    component: CalendarModule.Calendar,
    name: 'Calendar'
  },
  // setting
  {
    path: '/setting',
    component: SettingModule.Setting,
    name: 'Setting'
  },
  // public
  {
    path: '/login',
    component: LoginModule.Login,
    name: 'Login',
    isPublic: true
  },
  {
    path: '/reset-password/:token',
    component: ResetPasswordModule.ResetPassword,
    name: 'Reset Password',
    isPublic: true
  },
  {
    path: '/change-password',
    component: ChangePasswordModule.ChangePassword,
    name: 'Change Password',
    isPublic: true
  }
];

export default allRoutes;
