import axios from 'axios';
/*
    "firstName": "String",
    "lastName": "String",
    "phone": String,
    "language": "EN", "DE"
*/

const updateUserApi = async (params) => {
  const apiUpdateUser = `${process.env.REACT_APP_API_ENDPOINT}/user`;
  const { data } = await axios.patch(apiUpdateUser, {
    ...params
  });
  return data;
};

export default updateUserApi;
