import { format, subDays } from 'date-fns';
import get from 'lodash.get';
import React from 'react';
import CalendarTable from './components/calendarTable';
/* Gen Title */
const onHandleGenTitle = (calendarApi, translate, startDate, endDate) => {
  const currentViewType = get(
    calendarApi,
    'currentDataManager.state.currentViewType'
  );
  const endDateFormat = subDays(new Date(endDate), 1);
  const monthStart = format(new Date(startDate), 'MMMM').toLowerCase();
  const monthEnd = format(new Date(endDateFormat), 'MMMM').toLowerCase();
  const dayStart = format(new Date(startDate), 'dd');
  const dayEnd = format(new Date(endDateFormat), 'dd');
  const yearStart = format(new Date(startDate), 'Y');
  const yearEnd = format(new Date(endDateFormat), 'Y');

  const genMonthStart = translate(`lbl_${monthStart}_calendar_header`);
  const genMonthEnd = translate(`lbl_${monthEnd}_calendar_header`);

  if (currentViewType === 'timeGridWeek') {
    if (yearStart === yearEnd) {
      if (monthStart === monthEnd) {
        return `${genMonthStart} ${dayStart} - ${dayEnd}, ${yearEnd}`;
      }

      return `${genMonthStart} ${dayStart} - ${genMonthEnd} ${dayEnd}, ${yearEnd}`;
    }
    return `${genMonthStart} ${dayStart}, ${yearStart} - ${genMonthEnd} ${dayEnd}, ${yearEnd}`;
  }

  if (currentViewType === 'timeGridDay') {
    return `${genMonthStart} ${dayStart}, ${yearStart}`;
  }

  if (currentViewType === 'dayGridMonth') {
    return `${genMonthStart}, ${yearStart}`;
  }

  return '';
};

const Calendar = () => {
  const calendarTableProps = {
    onHandleGenTitle
  };

  return (
    <div className="main-calendar">
      <CalendarTable {...calendarTableProps} />
    </div>
  );
};

export default Calendar;
