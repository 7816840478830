import { Drawer } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import './DrawerEvent.less';
import DrawerEventContent from './DrawerEventContent';
import DrawerEventHeader from './DrawerEventHeader';

const DrawerEvent = ({ visible, onClose }) => {
  const genDrawerEventHeader = useMemo(() => <DrawerEventHeader />, []);
  const genDrawerEventDetailContent = useMemo(() => <DrawerEventContent />, []);

  return (
    <Drawer
      title={genDrawerEventHeader}
      placement="right"
      closable={false}
      onClose={onClose}
      visible={visible}
      width="304"
    >
      {genDrawerEventDetailContent}
    </Drawer>
  );
};

DrawerEvent.propTypes = {
  onClose: PropTypes.func,
  visible: PropTypes.bool
};

DrawerEvent.defaultProps = {
  onClose: () => {},
  visible: false
};

export default DrawerEvent;
