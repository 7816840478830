/* eslint-disable import/prefer-default-export */
export const STATUS_CODE = {
  SUCCESS: 200,
  UNAUTHORIZATION: 403
};

export const STATUS_REQUEST = {
  REQUEST: 'request',
  SUCCESS: 'success',
  FAILED: 'failed'
};
