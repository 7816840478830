import React from 'react';
import Icon from '@/components/common/icon';

const genCalendarIcon = (selectedKey) => <Icon.CalendarIcon selectedKey={selectedKey} />;
const genSettingOutlined = () => <Icon.SettingOutlined />;
const sideBarLinks = [
  {
    type: 'lbl_operations',
    menuItem: [
      {
        id: 'calendar',
        title: 'lbl_calendar',
        icon: (selectedKey) => genCalendarIcon(selectedKey),
        url: '/calendar',
        acceptRoles: ''
      }
    ]
  },
  {
    type: 'lbl_admins',
    menuItem: [
      {
        id: 'setting',
        title: 'lbl_setting',
        icon: () => genSettingOutlined(),
        url: '/setting',
        acceptRoles: ''
      }
    ]
  }
];

export default sideBarLinks;
