/* eslint-disable no-return-assign */
import * as Avatar from '@/components/common/avatar';
import Icon from '@/components/common/icon';
import localStorageService from '@/shared/services/localStorageService';
import {
  Button, Dropdown, Layout, Menu
} from 'antd';
import get from 'lodash.get';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './NavigationHeader.less';

const { Header } = Layout;

const AvatarMenu = () => {
  const onHandleMoveLogin = () => window.location = '/login';
  const onHandleMoveSetting = () => window.location = '/setting';
  const { t: translate } = useTranslation();
  const user = JSON.parse(localStorageService.getuserInfo());

  return (
    <Menu className="info-container">
      <Menu.Item className="user-name-title-group">
        <Avatar.AvatarNoNamePlaceHolder size="medium" />
        <div className="user-name-title">
          <span className="user-name">
            {`${get(user, 'firstName')} ${get(user, 'lastName')}`}
          </span>
          <span className="user-title">{get(user, 'userRole')}</span>
        </div>
      </Menu.Item>
      <Menu.Item className="user-email">
        <Icon.EmailIcon />
        <span className="user-email-info">{get(user, 'email')}</span>
      </Menu.Item>
      <Menu.Item className="user-control-btn">
        <Button onClick={onHandleMoveLogin} className="user-btn-logout">
          {translate('btn_logout')}
        </Button>
        <Button onClick={onHandleMoveSetting} className="user-btn-detail">
          {translate('btn_show_detail')}
        </Button>
      </Menu.Item>
    </Menu>
  );
};

const NavigationHeader = () => {
  const [isClick, setIsClicked] = useState(false);
  const isClickShowInfoUser = () => setIsClicked(!isClick);
  return (
    <Header>
      <div className="header-container">
        <Icon.SoniqLogoIcon style={{ paddingLeft: '24px' }} />
        <Menu mode="horizontal">
          <Menu.Item key="2">
            <div>
              <Avatar.AvatarNoNamePlaceHolder
                size="medium"
                className="avatar-user"
              />
              <Dropdown
                visible={isClick}
                overlay={<AvatarMenu />}
                overlayClassName="info-menu"
                trigger={['click']}
                arrow={false}
              >
                <Button
                  className="dropdown-btn"
                  data-testid="dropdown-btn-test"
                  onClick={isClickShowInfoUser}
                >
                  {isClick ? <Icon.UpArrowIcon /> : <Icon.DownArrowIcon />}
                </Button>
              </Dropdown>
            </div>
          </Menu.Item>
        </Menu>
      </div>
    </Header>
  );
};

export default NavigationHeader;
