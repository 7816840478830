import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';
import LanguageFilter from '@/components/common/languageFilter';
import './SettingLanguage.less';

const SettingLanguage = () => {
  const { t: translate } = useTranslation();
  return (
    <div className="language-info-container">
      <h1 className="language-info-title">
        {translate('txt_setting_language_info_title')}
      </h1>
      <h1 className="language-info-sub-title">
        {translate('txt_setting_language_info_sub_title')}
      </h1>
      <div className="language-info-form-container">
        <Row gutter={16}>
          <Col span={8}>
            <LanguageFilter border />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SettingLanguage;
