import FullCalendar from '@fullcalendar/react';
import classnames from 'classnames';
import calendarTableconfig from '@/configs/calendarTable.config';
import localStorageService from '@/shared/services/localStorageService';
import * as DrawerComnponent from '@/components/common/drawer';
import { format } from 'date-fns';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import React, {
  createRef,
  useCallback, useEffect,
  useMemo,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../redux/actions';
import CalendarHeader from '../calendarHeader';
import './CalendarTable.less';

const defaultCalendarView = 'timeGridWeek';

const CalendarTable = ({ onHandleGenTitle }) => {
  const [title, setTitle] = useState('');
  const [calendarViewMode, setCalendarViewMode] = useState(defaultCalendarView);
  const { openDrawer } = DrawerComnponent.drawerAction;
  const calendarRef = createRef();
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const {
    calendarReducer: { events, isLoading, locations }
  } = useSelector((state) => state);

  const onHandleUsingCalendarApi = useCallback(
    (type, params) => {
      const calendarApi = calendarRef.current.getApi();
      switch (type) {
        case 'title': {
          const startCurrent = calendarApi.view.currentStart;
          const endCurrent = calendarApi.view.currentEnd;
          const titleUpdate = onHandleGenTitle(
            calendarApi,
            translate,
            startCurrent,
            endCurrent
          );
          setTitle(titleUpdate);
          return titleUpdate;
        }
        case 'startDate':
          return new Date(calendarApi.view.currentStart).getTime();
        case 'endDate':
          return new Date(calendarApi.view.currentEnd).getTime();
        case 'scrollToTime':
          return calendarApi.scrollToTime(params);
        case 'changeView':
          setCalendarViewMode(params);
          return calendarApi.changeView(params);
        case 'paginator':
          return calendarApi[params]();
        case 'gotoDate': {
          const formatDate = format(new Date(params), 'yyyy-MM-dd\'T\'HH:mm:ss');
          return calendarApi.gotoDate(formatDate);
        }
        default:
          break;
      }
      return '';
    },
    [calendarRef, onHandleGenTitle, translate]
  );

  const onHandleFetchEvents = useCallback(() => {
    const startDate = onHandleUsingCalendarApi('startDate');
    const endDate = onHandleUsingCalendarApi('endDate');
    const userInfo = JSON.parse(localStorageService.getuserInfo());
    dispatch(actions.setCalendarLoading());
    dispatch(
      actions.fetchCalendarEventsRequest({
        startDate,
        endDate,
        location: get(userInfo, 'locationId') ? userInfo.locationId : ''
      })
    );
  }, [dispatch, onHandleUsingCalendarApi]);

  /* function  fetch events first time */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onHandleFetchEvents(), []);

  /* Get title , startInitDate , endInitDate */
  useEffect(() => onHandleUsingCalendarApi('title'), [title, onHandleUsingCalendarApi]);

  /* Scroll to current time when calendar view mode is changed */
  useEffect(() => {
    const currentTime = format(new Date(), 'HH:mm:ss');
    onHandleUsingCalendarApi('scrollToTime', currentTime);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* function refresh fetch events */
  const onRefreshEvent = useCallback((e) => {
    e.preventDefault();
    onHandleFetchEvents();
  }, [onHandleFetchEvents]);

  /* function calendar view mode */
  const onChangeViewMode = useCallback(
    (e, mode) => {
      e.preventDefault();
      onHandleUsingCalendarApi('changeView', mode);
      onHandleUsingCalendarApi('title');
    },
    [onHandleUsingCalendarApi]
  );

  /* function using next/previous calendar view -> fetch events */
  const onClickPaginator = useCallback(
    (e, type) => {
      e.preventDefault();
      onHandleUsingCalendarApi('paginator', type);
      onHandleUsingCalendarApi('title');
      onHandleFetchEvents();
    },
    [onHandleFetchEvents, onHandleUsingCalendarApi]
  );

  /* function move calendar to selection date -> fetch events */
  const onSelectDay = useCallback((date) => {
    onHandleUsingCalendarApi('gotoDate', date);
    onHandleUsingCalendarApi('title');
    onHandleFetchEvents();
  }, [onHandleUsingCalendarApi, onHandleFetchEvents]);

  const onClickEvent = (item) => {
    const idSelected = get(item, 'event._def.publicId');
    if (idSelected) {
      dispatch(actions.setCalendarLoading());
      dispatch(
        actions.fetchCalendarEventRequest({
          eventId: idSelected
        })
      );
      dispatch(openDrawer('eventDetailDrawer'));
    }
  };

  const CalendarContainerClass = classnames('calendar-container', {
    blur: isLoading
  });

  const gendayHeader = useMemo(
    // eslint-disable-next-line react/display-name
    () => (args) => {
      const day = format(new Date(args), 'cccc');
      const date = format(new Date(args), 'dd');
      return (
        <>
          <div className="calender-week-days">
            {translate(`lbl_${day.toLocaleLowerCase()}_calendar_header`)}
          </div>
          {calendarViewMode === 'timeGridWeek' && (
            <div className="calender-month-days">{date}</div>
          )}
        </>
      );
    },
    [calendarViewMode, translate]
  );

  const propsCalendarheader = useMemo(() => ({
    title,
    onRefreshEvent,
    viewMode: calendarViewMode,
    onSelectDay,
    onChangeView: onChangeViewMode,
    onClickPaginator,
    locationItem: locations
  }), [calendarViewMode, locations, onChangeViewMode, onClickPaginator, onRefreshEvent, onSelectDay, title]);
  const propsFullCalendar = {
    ...calendarTableconfig,
    events: events.map((event) => {
      const eventFormat = {
        id: event.eventId,
        title: event.eventName,
        start: event.startTime,
        end: event.endTime
      };
      return eventFormat;
    }),
    initialView: defaultCalendarView,
    dayHeaderContent: (args) => gendayHeader(args.date),
    eventClick: (item) => onClickEvent(item)
  };

  const genCalendarHeader = useMemo(
    () => <CalendarHeader {...propsCalendarheader} />,
    [propsCalendarheader]
  );
  return (
    <>
      {genCalendarHeader}
      <div className={CalendarContainerClass}>
        <FullCalendar ref={calendarRef} {...propsFullCalendar} />
      </div>
    </>
  );
};

export default CalendarTable;

CalendarTable.propTypes = {
  onHandleGenTitle: PropTypes.func
};

CalendarTable.defaultProps = {
  onHandleGenTitle: () => {}
};
