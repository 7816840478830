import * as ActionTypes from '@/constants/actionTypes';

export const setCalendarLoading = () => ({
  type: ActionTypes.CALENDAR_ACTION_TYPES.SET_LOADING
});

// events
export const fetchCalendarEventsRequest = ({
  startDate,
  endDate,
  location
}) => ({
  type: ActionTypes.CALENDAR_ACTION_TYPES.FETCH_EVENTS_REQUEST,
  payload: {
    startDate,
    endDate,
    location
  }
});
export const fetchCalendarEvents = (data) => ({
  type: ActionTypes.CALENDAR_ACTION_TYPES.FETCH_EVENTS,
  payload: data
});
export const fetchCalendarEventsFailed = (error) => ({
  type: ActionTypes.CALENDAR_ACTION_TYPES.FETCH_EVENTS_FAILED,
  payload: error
});

// event_detail
export const fetchCalendarEventRequest = ({ eventId }) => ({
  type: ActionTypes.CALENDAR_ACTION_TYPES.FETCH_EVENT_REQUEST,
  payload: {
    eventId
  }
});
export const fetchCalendarEvent = (data) => ({
  type: ActionTypes.CALENDAR_ACTION_TYPES.FETCH_EVENT,
  payload: data
});
export const fetchCalendarEventFailed = (error) => ({
  type: ActionTypes.CALENDAR_ACTION_TYPES.FETCH_EVENT_FAILED,
  payload: error
});
