import axios from 'axios';

const requestResetPassword = async (params) => {
  const { password, token } = params;
  const { data } = await axios.post(
    `${process.env.REACT_APP_API_ENDPOINT}/register-password`,
    {
      password,
      token
    }
  );
  return data;
};

export default requestResetPassword;
