
import * as types from '@/constants/actionTypes';
import {
  call, fork, put, takeLatest
} from 'redux-saga/effects';
import api from '../api';
import * as actions from './actions';

function* requestLogin(params) {
  const { payload } = params;
  try {
    const data = yield call(api.requestLogin, payload);
    document.cookie = `access_token=${data.token}`;
    yield put(actions.requestLoginSuccess(data.user));
  } catch (error) {
    yield put(actions.requestLoginFailed(error));
  }
}

function* watchRequestLogin() {
  yield takeLatest(types.AUTH_ACTION_TYPES.LOGIN_REQUEST, requestLogin);
}

const loginSagas = [requestLogin, fork(watchRequestLogin)];

export default loginSagas;
