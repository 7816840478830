import illustrationLogin from '@/assets/img/illustration/illustration-login.svg';
import Icon from '@/components/common/icon';
import Illustration from '@/components/common/illustration';
import LanguageFilter from '@/components/common/languageFilter';
import {
  Button, Col, Form, Input, message, Row
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import './Login.less';
import cookieService from '@/shared/services/cookieService';
import { requestLogin } from './redux/actions';

const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t: translate, i18n } = useTranslation();
  const [form] = Form.useForm();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isForgotPassword, setIsForgotPassword] = useState(false);

  const {
    loginReducer: { isError, isLoading }
  } = useSelector((state) => state);
  const isAuthenticated = cookieService.getCookie('access_token');

  useEffect(() => {
    localStorage.clear();
    document.cookie = 'access_token=';
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/calendar');
    }
  }, [isAuthenticated, history]);

  const handleLogin = () => {
    const languageSelected = i18n.language;
    if (email && password) {
      dispatch(requestLogin({ email, password, languageSelected }));
    } else {
      message.error('E-mail and password are required');
    }
  };

  const renderForgotPassword = () => (
    <div className="forgot-password-container">
      <div className="login-header">
        <h1 className="title">
          {translate('txt_login_password_reset_title')}
        </h1>
        <p className="content">
          {translate('txt_login_password_reset_sub_title')}
        </p>
      </div>
      <Form layout="vertical">
        <Row>
          <Col span={9}>
            <Form.Item label={translate('txt_login_email_address')}>
              <Input
                size="large"
                onChange={(event) => setEmail(event.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Button type="primary" className="recovery-btn">
          {translate('btn_login_recovery_link')}
        </Button>
        <Button
          type="text"
          className="remember-password-btn"
          onClick={() => setIsForgotPassword(false)}
        >
          {translate('txt_login_remember_password')}
        </Button>
      </Form>
    </div>
  );

  const renderErrorMessage = () => (
    <div className="login-registration-error">
      <p>
        {translate('txt_login_registration_error_not_match')}
        <br />
        {translate('txt_login_registration_error_enter_again')}
      </p>
    </div>
  );

  const renderLoginForm = () => (
    <div className="login-form-container">
      <div className="login-header">
        <h1 className="title">{translate('txt_login_title')}</h1>
        <p className="content">{translate('txt_login_content')}</p>
      </div>
      <Form form={form} layout="vertical">
        <Row gutter={24}>
          <Col span={9}>
            <Form.Item label={translate('txt_login_email_address')}>
              <Input
                size="large"
                style={isError ? { borderColor: '#DD3804' } : null}
                onChange={(event) => setEmail(event.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item
              label={translate('txt_login_password')}
              className="login-password"
            >
              <Input.Password
                size="large"
                style={isError ? { borderColor: '#DD3804' } : null}
                onChange={(event) => setPassword(event.target.value)}
              />
              <div className="forgot-password">
                <Button
                  type="text"
                  className="forgot-password-btn"
                  onClick={() => setIsForgotPassword(true)}
                >
                  {translate('txt_login_forgot_password')}
                </Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
        {isError && renderErrorMessage()}
        <Form.Item>
          <Button
            type="primary"
            className="login-btn"
            loading={isLoading}
            onClick={() => handleLogin()}
          >
            {translate('btn_login')}
          </Button>
        </Form.Item>
      </Form>
      <div className="login-footer">
        <p>
          {translate('txt_login_dont_have_account')}
          <span>
            <a href="mailto:support@soniqservices.de">
              {` ${translate('txt_login_contact_us')}`}
            </a>
          </span>
        </p>
      </div>
    </div>
  );
  return (
    <div className="auth-container">
      <div className="login-container">
        <div className="container-header">
          <Icon.SoniqLogoIcon />
          <LanguageFilter />
        </div>
        {isForgotPassword ? renderForgotPassword() : renderLoginForm()}
      </div>
      <div className="illustration-container">
        <Illustration isLogin illustrationImg={illustrationLogin} />
      </div>
    </div>
  );
};

export default Login;
