import * as ActionTypes from '@/constants/actionTypes';
import localStorageService from '@/shared/services/localStorageService';

export const initialState = {
  isLoading: false,
  isUpdateSuccess: false
};

const settingReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SETTING_ACTION_TYPES.UPDATE_USER_SUCCESS: {
      const result = action.payload.data;
      localStorageService.setUserInfo({ userInfo: result.user });
      return {
        ...state,
        isLoading: false,
        isUpdateSuccess: true
      };
    }
    case ActionTypes.SETTING_ACTION_TYPES.SET_LOADING: {
      return {
        ...state,
        isLoading: true,
        isUpdateSuccess: false
      };
    }
    default: {
      return state;
    }
  }
};

export default settingReducer;
