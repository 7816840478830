import Icon from '@/components/common/icon';
import { Avatar } from 'antd';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import './Avatar.less';

const avatarClass = (size, type) => classnames('avatar-container', {
  placeholder: type === 'has-name',
  photo: type === 'photo',
  sm: size === 'small',
  md: size === 'medium',
  lg: size === 'large',
  'x-lg': size === 'x-large'
});
const sizeFormat = (size) => {
  switch (size) {
    case 'x-large':
      return 64;
    case 'large':
      return 'large';
    case 'small':
      return 'small';
    default:
      return '';
  }
};

export const AvatarPlaceHolder = ({ title, size }) => <div className={avatarClass(size, 'has-name')}>{title}</div>;

AvatarPlaceHolder.propTypes = {
  size: PropTypes.string,
  title: PropTypes.string
};

AvatarPlaceHolder.defaultProps = {
  size: '',
  title: ''
};

export const AvatarNoNamePlaceHolder = ({ size }) => <Avatar size={sizeFormat(size)} icon={<Icon.UserOutlined />} />;

AvatarNoNamePlaceHolder.propTypes = {
  size: PropTypes.string
};

AvatarNoNamePlaceHolder.defaultProps = {
  size: ''
};

export const AvatarPhoto = ({ src, size }) => (
  <div className={avatarClass(size, 'photo')}>
    <img src={src} alt="avatar" />
  </div>
);

AvatarPhoto.propTypes = {
  size: PropTypes.string,
  src: PropTypes.string
};

AvatarPhoto.defaultProps = {
  size: '',
  src: ''
};
