import illustrationResetPW from '@/assets/img/illustration/illustration-resetPW.svg';
import Icon from '@/components/common/icon';
import Illustration from '@/components/common/illustration';
import LanguageFilter from '@/components/common/languageFilter';
import { validatePassword } from '@/shared/helpers/commonHelpers';
import {
  Button, Col, Form, Input, message, Row
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import RegistrationSuccess from '../common/successPage';
import { requestResetPassword } from './redux/actions';
import './ResetPassword.less';

const ResetPassword = () => {
  const { t: translate } = useTranslation();
  const [form] = Form.useForm();
  const [password, setPassword] = useState('');
  const [reEnterPassword, setReEnterPassword] = useState('');
  const [isPasswordValidated, setIsPasswordValidated] = useState(false);
  const [isShowError, setIsShowError] = useState(false);
  const { token } = useParams();
  const dispatch = useDispatch();
  const {
    resetPasswordReducer: { isSuccess }
  } = useSelector((state) => state);

  useEffect(() => {
    if (isPasswordValidated && token) {
      dispatch(requestResetPassword({ password, token }));
    }
  }, [dispatch, password, isPasswordValidated, token]);

  const handleResetPassword = () => {
    if (!password || !reEnterPassword) {
      message.error('Password and Re-enter Password are required');
      return;
    }
    if (validatePassword(password, reEnterPassword)) {
      setIsShowError(true);
    } else {
      setIsPasswordValidated(true);
    }
  };

  const renderErrorMessage = () => (
    <div className="login-registration-error">
      <p>
        {translate('txt_login_registration_error_not_match')}
        <br />
        {translate('txt_login_registration_error_enter_again')}
      </p>
    </div>
  );

  const renderResetPassword = () => (
    <div className="login-header">
      <h1 className="title">{translate('txt_registration_title')}</h1>
      <div className="content">
        <p>{translate('txt_registration_sub_title')}</p>
        <ul className="list-require">
          <li>{translate('txt_registration_rule_1')}</li>
          <li>{translate('txt_registration_rule_2')}</li>
          <li>{translate('txt_registration_rule_3')}</li>
          <li>{translate('txt_registration_rule_4')}</li>
        </ul>
        <Form form={form} layout="vertical">
          <Row gutter={24}>
            <Col span={9}>
              <Form.Item label={translate('txt_registration_new_password')}>
                <Input.Password
                  size="large"
                  style={isShowError ? { borderColor: '#DD3804' } : null}
                  onChange={(event) => setPassword(event.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={9}>
              <Form.Item
                label={translate('txt_registration_reEnter_password')}
              >
                <Input.Password
                  size="large"
                  style={isShowError ? { borderColor: '#DD3804' } : null}
                  onChange={(event) => setReEnterPassword(event.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
          {isShowError ? renderErrorMessage() : null}
          <Form.Item className="login-btn">
            <Button type="primary" onClick={() => handleResetPassword()}>
              {translate('btn_registration_change_password')}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );

  return (
    <div className="auth-container">
      <div className="registration-container">
        <div className="container-header">
          <Icon.SoniqLogoIcon />
          {!isSuccess && <LanguageFilter />}
        </div>
        {!isSuccess ? renderResetPassword() : <RegistrationSuccess title="Password reset" />}
      </div>
      <div className="illustration-container">
        <Illustration isLogin={false} illustrationImg={illustrationResetPW} />
      </div>
    </div>
  );
};

export default ResetPassword;
