import * as types from '@/constants/actionTypes';
import {
  call, fork, put, takeLatest
} from 'redux-saga/effects';
import api from '../api';
import * as actions from './actions';

function* requestResetPassword(params) {
  const { payload } = params;
  try {
    yield call(api.requestResetPassword, payload);
    yield put(actions.requestResetPasswordSuccess());
  } catch (error) {
    yield put(actions.requestResetPasswordFailed(error));
  }
}

function* watchRequestResetPassword() {
  yield takeLatest(
    types.RESET_PASSWORD_ACTION_TYPES.RESET_PASSWORD_REQUEST,
    requestResetPassword
  );
}

const resetPasswordSagas = [
  requestResetPassword,
  fork(watchRequestResetPassword)
];

export default resetPasswordSagas;
