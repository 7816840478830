import * as types from '@/constants/actionTypes';
import localStorageService from '@/shared/services/localStorageService';

export const initialState = {
  isLoading: false,
  isError: false
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.AUTH_ACTION_TYPES.LOGIN_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false
      };
    }
    case types.AUTH_ACTION_TYPES.LOGIN_REQUEST_SUCCESS: {
      localStorageService.setUserInfo(action.payload);
      return {
        ...state,
        isLoading: false,
        isError: false
      };
    }
    case types.AUTH_ACTION_TYPES.LOGIN_REQUEST_FAILED: {
      return {
        ...state,
        isLoading: false,
        isError: true
      };
    }
    default:
      return state;
  }
};

export default loginReducer;
