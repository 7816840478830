import sideBarLinks from '@/configs/sidebar.config';
import { Layout, Menu } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import './NavigationSidebar.less';

const { Sider } = Layout;

const NavigationSidebar = () => {
  const { t: translate } = useTranslation();
  const history = useHistory();
  const [selectedKey, setSelectedKey] = useState(
    history.location.pathname.replace(/\//g, '') || 'calendar'
  );

  const onHandleClickItem = (url) => {
    setSelectedKey(url.replace(/\//g, ''));
    history.push(url);
  };

  return (
    <Sider width={240} className="site-layout-background">
      <Menu
        mode="inline"
        defaultSelectedKeys={[`${selectedKey}`]}
        className="sidebar-container"
      >
        {sideBarLinks.map((item) => {
          const { type, menuItem } = item;
          return (
            <Menu.ItemGroup title={translate(type)} key={type}>
              {menuItem.map((value) => {
                const {
                  title, icon, id, url
                } = value;
                return (
                  <Menu.Item
                    icon={icon(selectedKey)}
                    data-testid={`item${
                      selectedKey === id
                        ? `-${selectedKey} selected`
                        : `-${title}`
                    }`}
                    key={id}
                    onClick={() => onHandleClickItem(url)}
                  >
                    {translate(title)}
                  </Menu.Item>
                );
              })}
            </Menu.ItemGroup>
          );
        })}
      </Menu>
    </Sider>
  );
};

export default NavigationSidebar;
