import { DatePicker } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import './CalendarDatePicker.less';
import moment from 'moment';


moment.locale('en', {
  week: {
    dow: 1
  }
});

const CalendarDatePicker = ({ onSelectedDay }) => {
  const onSelect = (date) => {
    onSelectedDay(new Date(date));
  };

  return (
    <div className="calendar-picker-container">
      <DatePicker allowClear={false} onChange={onSelect} />
    </div>
  );
};

export default CalendarDatePicker;

CalendarDatePicker.propTypes = {
  onSelectedDay: PropTypes.func
};

CalendarDatePicker.defaultProps = {
  onSelectedDay: () => {}
};
