import { createContext } from 'react';
import Setting from './Setting';
import settingSagas from './redux/sagas';
import settingReducer from './redux/reducer';

const SettingContext = createContext();

export {
  Setting, SettingContext, settingSagas, settingReducer
};
