import {
  takeLatest, call, put, fork
} from 'redux-saga/effects';
import * as ActionTypes from '@/constants/actionTypes';
import api from '../api';
import * as actions from './actions';

function* fetchEvents(params) {
  const { payload } = params;
  try {
    const result = yield call(api.fetchEventsAPI, payload);
    yield put(actions.fetchCalendarEvents(result));
  } catch (e) {
    yield put(actions.fetchCalendarEventsFailed(e));
  }
}

function* fetchEvent(params) {
  const { payload } = params;
  try {
    const result = yield call(api.fetchEventAPI, payload);
    yield put(actions.fetchCalendarEvent(result));
  } catch (e) {
    yield put(actions.fetchCalendarEventFailed(e));
  }
}

function* watchFetchEventsRequest() {
  yield takeLatest(
    ActionTypes.CALENDAR_ACTION_TYPES.FETCH_EVENTS_REQUEST,
    fetchEvents
  );
}

function* watchFetchEventRequest() {
  yield takeLatest(
    ActionTypes.CALENDAR_ACTION_TYPES.FETCH_EVENT_REQUEST,
    fetchEvent
  );
}

const calendarSagas = [
  fetchEvents,
  fork(watchFetchEventsRequest),
  fork(watchFetchEventRequest)
];

export default calendarSagas;
