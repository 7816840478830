import PropTypes from 'prop-types';
import React from 'react';
import NavigationHeader from './components/navigationHeader';
import NavigationSidebar from './components/navigationSidebar';

const Navigation = ({ type }) => (type === 'header' ? <NavigationHeader /> : <NavigationSidebar />);

export default Navigation;
Navigation.propTypes = {
  type: PropTypes.string
};

Navigation.defaultProps = {
  type: ''
};
