import taskIcon from '@/assets/img/calendar/task.svg';
import emailIcon from '@/assets/img/email-icon.svg';
import reloadCalendar from '@/assets/img/reload-calendar.svg';
import languageIcon from '@/assets/img/language.svg';
import soniQLogo from '@/assets/img/logo.svg';
import calendarActiveIcon from '@/assets/img/navigation/calendar-active.svg';
import calendarIcon from '@/assets/img/navigation/calendar.svg';
import downArrow from '@/assets/img/navigation/down-arrow.svg';
import filterIcon from '@/assets/img/navigation/filter.svg';
import locationActiveIcon from '@/assets/img/navigation/location-active.svg';
import locationIcon from '@/assets/img/navigation/location.svg';
import ticketActiveIcon from '@/assets/img/navigation/ticket-active.svg';
import ticketIcon from '@/assets/img/navigation/ticket.svg';
import upArrow from '@/assets/img/navigation/up-arrow.svg';
import * as IconAnt from '@ant-design/icons';
import PropTypes from 'prop-types';
import React from 'react';
import './index.less';

const SoniqLogoIcon = (props) => (
  <div className="logo" {...props}>
    <img src={soniQLogo} alt="SoniQ Logo" />
    <div className="logo-label">
      <span className="name">SoniQ Services</span>
      <span className="sign">TM</span>
    </div>
  </div>
);

const CalendarIcon = ({ selectedKey }) => (
  <img
    src={selectedKey === 'calendar' ? calendarActiveIcon : calendarIcon}
    alt="calendar"
    className="calender-icon"
  />
);

CalendarIcon.propTypes = {
  selectedKey: PropTypes.string
};

CalendarIcon.defaultProps = {
  selectedKey: ''
};

const LocationIcon = ({ selectedKey }) => (
  <img
    src={selectedKey === 'location' ? locationActiveIcon : locationIcon}
    alt="location"
    className="location-icon"
  />
);

LocationIcon.propTypes = {
  selectedKey: PropTypes.string
};

LocationIcon.defaultProps = {
  selectedKey: ''
};

const TicketIcon = ({ selectedKey }) => (
  <img
    src={selectedKey === 'ticket' ? ticketActiveIcon : ticketIcon}
    alt="ticket"
    className="ticket-icon"
  />
);

TicketIcon.propTypes = {
  selectedKey: PropTypes.string
};

TicketIcon.defaultProps = {
  selectedKey: ''
};

const FilterIcon = () => <img src={filterIcon} alt="filter" className="filter-icon" />;

const TaskIcon = () => <img src={taskIcon} alt="task" className="task-icon" />;

const EmailIcon = () => <img src={emailIcon} alt="email" className="email-icon" />;

const DownArrowIcon = () => <img src={downArrow} alt="down-arrow" className="arrow" />;

const UpArrowIcon = () => <img src={upArrow} alt="up-arrow" className="arrow" />;

const LanguageIcon = () => <img src={languageIcon} alt="language" className="language-filter-icon" />;

const ReloadCalendar = () => <img src={reloadCalendar} alt="reload-calendar" />;

const Icon = {
  SoniqLogoIcon,
  CalendarIcon,
  LocationIcon,
  TicketIcon,
  FilterIcon,
  TaskIcon,
  EmailIcon,
  DownArrowIcon,
  UpArrowIcon,
  LanguageIcon,
  ReloadCalendar,
  ...IconAnt
};
export default Icon;
