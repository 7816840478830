/* eslint-disable react/prop-types */
import { handleCheckPublicRoute } from '@/shared/helpers/commonHelpers';
import { Layout } from 'antd';
import classnames from 'classnames';
import React from 'react';
import { useLocation } from 'react-router-dom';
import './LayoutModule.less';

const LayoutModule = ({ children }) => {
  const location = useLocation();
  const moduleWrapperClassname = classnames(
    'site-layout-background',
    {
      'module-wrapper_public': handleCheckPublicRoute(location.pathname),
      'module-wrapper_private': !handleCheckPublicRoute(location.pathname)
    }
  );
  return (
    <Layout className="site-layout">
      <div className={moduleWrapperClassname}>
        {children}
      </div>
    </Layout>
  );
};

export default LayoutModule;