import * as types from '@/constants/actionTypes';

export const requestChangePassword = (payload) => ({
  type: types.CHANGE_PASSWORD_ACTION_TYPES.CHANGE_PASSWORD_REQUEST,
  payload
});

export const requestChangePasswordSuccess = () => ({
  type: types.CHANGE_PASSWORD_ACTION_TYPES.CHANGE_PASSWORD_REQUEST_SUCCESS,
});

export const requestChangePasswordFailed = () => ({
  type: types.CHANGE_PASSWORD_ACTION_TYPES.CHANGE_PASSWORD_REQUEST_FAILED
});

export const resetChangePasswordState = () => ({
  type: types.CHANGE_PASSWORD_ACTION_TYPES.RESET_CHANGE_PASSWORD_STATE
});