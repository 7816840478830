import * as CalendarModule from '@/components/calendar';
import * as ChangePasswordModule from '@/components/changePassword';
import * as LoginModule from '@/components/login';
import * as SettingModule from '@/components/setting';
import * as ResetPasswordModule from '@/components/resetPassword';
import { all } from 'redux-saga/effects';


export default function* rootSaga() {
  yield all([
    ...CalendarModule.calendarSagas,
    ...ResetPasswordModule.resetPasswordSagas,
    ...LoginModule.loginSagas,
    ...SettingModule.settingSagas,
    ...ChangePasswordModule.changePasswordSaga
  ]);
}