import * as CalendarModule from '@/components/calendar';
import * as ChangePasswordModule from '@/components/changePassword';
import * as DrawerComponent from '@/components/common/drawer';
import * as LoginModule from '@/components/login';
import * as ResetPasswordMoudle from '@/components/resetPassword';
import * as SettingModule from '@/components/setting';
import { combineReducers } from 'redux';

const rootReducers = combineReducers({
  calendarReducer: CalendarModule.calendarReducer,
  resetPasswordReducer: ResetPasswordMoudle.resetPasswordReducer,
  loginReducer: LoginModule.loginReducer,
  drawerReducer: DrawerComponent.drawerReducer,
  changePasswordReducer: ChangePasswordModule.changePasswordReducer,
  settingReducer: SettingModule.settingReducer
});

export default rootReducers;