import { Button, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import localStorageService from '@/shared/services/localStorageService';
import get from 'lodash.get';
import { useDispatch, useSelector } from 'react-redux';
import SettingContact from './components/settingContact';
import SettingLanguage from './components/settingLanguage';
import SettingLogin from './components/settingLogin';
import { SettingContext } from './index';
import * as settingAction from './redux/actions';
import './Setting.less';

const Setting = () => {
  const userInfo = JSON.parse(localStorageService.getuserInfo());
  const settingFormInitValue = {
    name: get(userInfo, 'firstName') || '',
    surname: get(userInfo, 'lastName') || '',
    email: get(userInfo, 'email') || '',
    number: get(userInfo, 'phoneNumber') || '',
    language: get(userInfo, 'language') === 'English' ? 'en' : 'de'
  };
  const { t: translate, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [settingFromValue, setSettingFromValue] = useState(
    settingFormInitValue
  );
  const { settingReducer: { isLoading, isUpdateSuccess } } = useSelector((state) => state);

  useEffect(() => {
    if (!isLoading && isUpdateSuccess) {
      message.success(translate('lbl_update_user_success'));
    }
  }, [isLoading, isUpdateSuccess, translate]);

  const handleChangeSettingValue = (id, value) => {
    setSettingFromValue({
      ...settingFromValue,
      [id]: value
    });
  };

  const onHandleEditInfo = () => {
    const phoneNumber = get(settingFromValue, 'number');
    const params = {
      firstName: get(settingFromValue, 'name'),
      lastName: get(settingFromValue, 'surname'),
      phone: `${phoneNumber.slice(0, 4)} - ${phoneNumber.slice(4)} `,
      language: i18n.language
    };
    dispatch(settingAction.updateUserRequest(params));
  };

  const SettingContactProvider = {
    settingFromValue,
    handleChangeSettingValue
  };

  return (
    <div className="setting-container">
      <h1 className="title">{translate('txt_setting_title')}</h1>
      <h2 className="sub-title">{translate('txt_setting_sub_title')}</h2>
      <SettingContext.Provider value={SettingContactProvider}>
        <SettingContact />
        <SettingLogin />
        <SettingLanguage />
      </SettingContext.Provider>

      <div className="edit-info-container">
        <Button
          type="primary"
          className="edit-info-button"
          loading={isLoading}
          onClick={onHandleEditInfo}
        >
          {translate('btn_setting_edit_information')}
        </Button>
      </div>
    </div>
  );
};

export default Setting;
